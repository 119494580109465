<template>
    <div>
        <v-row no-gutters v-if="$vuetify.breakpoint.mdAndUp">
            <!-- <v-col cols=12 class="text-center">
                <app-date-range-picker
                    ref="picker"
                    :opens="'center'"
                    :locale-data="localdate"
                    :singleDatePicker="'range'"
                    :autoApply="true"
                    :ranges="false"
                    v-model="dateRange"
                    :linkedCalendars="false"
                    :alwaysShowCalendars="true"
            >
                <template v-slot:input="picker" style="min-width: 350px;">
                    {{ picker.startDate | shortDate}} - {{ picker.endDate | shortDate}}
                </template>
            </app-date-range-picker>
            </v-col> -->
            <v-col cols="12">
                <v-data-table light :headers="headers" :items="filteredDownlines" class="elevation-0" :hide-default-footer="true" hide-default-header :items-per-page="-1">
                    <template v-slot:header="{ props }">
                    <tr>
                        <th class="text-start referral-table-header" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px" v-for="head in props.headers" :key="head.text">
                           <span v-if="head.text !=='dateSearch'">
                               {{$t(`label.${head.text}`)}}
                            <span v-if="head.sortable" >
                                <v-icon v-if="head.sortBy == 'asc'" @click="sortTableList(head)">arrow_drop_down</v-icon>
                                <v-icon v-if="head.sortBy == 'desc'" @click="sortTableList(head)">arrow_drop_up</v-icon>
                            </span>
                           </span>
                            <v-menu v-if="head.text =='dateSearch'" ref="menu" v-model="dateOfBirthMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="dateRangeText" clearable @click:clear="clearFilter()" outlined class="date-search ml-3 elevation-0" hide-details="true" v-on="on" append-icon="search"></v-text-field>
                                </template>
                                <v-date-picker color="buttonPrimary" @change="filterListByDate()" no-title v-model="dates" @input="selectedDate()" range></v-date-picker>
                        </v-menu>
                    </th>
                </tr>
                </template>
                <template v-slot:body="{ items }">
                    <tr v-for="(item, index) in items" :key="index">
                        <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                            <v-menu v-model="item.menu" :close-on-content-click="false" :nudge-width="200" offset-x>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="pt-3 pb-2 px-0 text-capitalize member-id-btn" elevation="0" height="auto" text :ripple="false" light depressed dark v-on="on" v-bind="attrs">
                                    {{ item.member_id }}
                                    </v-btn>
                                </template>
                                <v-card class="pa-6" min-width="325">
                                    <v-row no-gutters align="end" justify="space-between" class="mb-6">
                                    <v-col cols=10>
                                         <label class="title">{{item.member_id}}</label>
                                    </v-col>
                                    <v-col cols=2>
                                       <v-btn
                                            icon
                                            @click="item.menu = false"
                                            >
                                            <v-icon>close</v-icon>
                                            </v-btn>
                                    </v-col>
                                    </v-row>
                                        <v-row no-gutters align="end">
                                        <v-col cols=7>
                                            <label class="text-start referral-table-header">{{ $t(`label.totalUser`) }}</label>
                                        </v-col>
                                        <v-col cols=5>
                                        {{item.total_members}}
                                        </v-col>
                                        <v-col cols=12>
                                        <v-divider class="my-3"></v-divider>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters align="end">
                                        <v-col cols=7>
                                            <label class="text-start referral-table-header">{{ $t(`label.totalCommission`) }}</label>
                                        </v-col>
                                        <v-col cols=5>
                                        {{ item.commission_earned | currency(currentCurrency) }}
                                        </v-col>
                                        <v-col cols=12>
                                        <v-divider class="my-3"></v-divider>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters align="end">
                                        <v-col cols=7>
                                            <label class="text-start referral-table-header">{{ $t(`label.totalDeposit`) }}</label>
                                        </v-col>
                                        <v-col cols=5>
                                        {{ item.total_deposit | currency(currentCurrency) }}
                                        </v-col>
                                    </v-row>
                                    </v-card>
                                </v-menu>
                                    </td>
                                    <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px" >
                                    {{ item.membercode }}
                                    </td>
                                    <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px" >
                                    {{ item.register_date_time | longDate }}
                                    </td>
                                    <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px" >
                                    {{ item.first_deposit_date_time | longDate }}
                                    </td>
                                    <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px" >
                                    {{ item.last_deposit_date_time | longDate }}
                                    </td>
                                    <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px" >
                                    {{ $t(`label.affiliateLevel`) }} {{ item.level }}
                                    </td>
                                    <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px" >
                                    </td>
                                </tr>
                                </template>
                                </v-data-table>
            </v-col>
        </v-row>
        <v-row no-gutters v-else>
            <v-col cols="12">
                <v-menu ref="menu" v-model="dateOfBirthMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                        <v-text-field v-model="dateRangeText" clearable @click:clear="clearFilter()" outlined class="date-search mb-3 elevation-0" hide-details="true" v-on="on" append-icon="search"></v-text-field>
                    </template>
                    <v-date-picker color="buttonPrimary" @change="filterListByDate()"  no-title v-model="dates" @input="dateOfBirthMenu = false" range></v-date-picker>
                </v-menu>
            </v-col>
             <v-col cols="12">
                 <v-expansion-panels accordion flat class="report-expansion-panel">
                <v-expansion-panel v-for="(item,i) in filteredDownlines" :key="i">
                <v-expansion-panel-header>
                    <v-row no-gutters>
                        <v-col cols=7>
                            <label class="referral-table-header">{{$t(`label.memberID`)}}</label>
                        </v-col>
                        <v-col cols=5>
                            {{item.member_id}}
                        </v-col>
                         <v-col cols=7 class="mt-2">
                            <label class="referral-table-header">{{$t(`label.totalCommission`)}}</label>
                        </v-col>
                        <v-col cols=5 class="mt-2">
                            {{ item.commission_earned | currency(currentCurrency) }}
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="expansion-panel-content">
                    <v-row no-gutters>
                        <v-col cols=6>
                            <label class="referral-table-header">{{$t(`label.memberCode`)}}</label>
                        </v-col>
                        <v-col cols=6>
                           {{ item.membercode }}
                        </v-col>
                         <v-col cols=6 class="mt-2">
                            <label class="referral-table-header">{{$t(`label.totalUser`)}}</label>
                        </v-col>
                        <v-col cols=6 class="mt-2">
                           {{ item.total_members }}
                        </v-col>
                         <v-col cols=6 class="mt-2">
                            <label class="referral-table-header">{{$t(`label.registerDate`)}}</label>
                        </v-col>
                        <v-col cols=6 class="mt-2">
                            {{ item.register_date_time | longDate}}
                        </v-col>
                        <v-col cols=6 class="mt-2">
                            <label class="referral-table-header">{{$t(`label.firstDepositDate`)}}</label>
                        </v-col>
                        <v-col cols=6 class="mt-2">
                            {{ item.first_deposit_date_time | longDate }}
                        </v-col>
                        <v-col cols=6 class="mt-2">
                            <label class="referral-table-header">{{$t(`label.lastDepositDate`)}}</label>
                        </v-col>
                        <v-col cols=6 class="mt-2">
                           {{ item.last_deposit_date_time | longDate }}
                        </v-col>
                        <v-col cols=6 class="mt-2">
                            <label class="referral-table-header">{{$t(`label.affiliateLevel`)}}</label>
                        </v-col>
                        <v-col cols=6 class="mt-2">
                            {{ item.level }}
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
                <v-divider class="my-3"></v-divider>
                </v-expansion-panel>
            </v-expansion-panels>
             </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" v-if="filteredDownlines.length == 0" class="text-center pt-0 pb-8" >
                <label class="referral-table-header subtitle-2">{{$t(`message.noDataAvaliable`)}}</label>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {
        ROUTE_NAME
    } from '@/constants/route.constants'
    import {
        uiHelper,
        locale,
        errorCodeHelper,
        formValidator
    } from '@/util'
    import {
        SHARED
    } from '@/constants/constants'
    import deleteIcon from '@/assets/svg/delete-icon.svg'
    import _ from 'lodash';
    import {
        REFERRAL_GET_OVERVIEW,
        REFERRAL_GET_DOWNLINES,
        REFERRAL_GET_REPORT
    } from "@/store/referral.module";
    export default {
        name: 'referralReport',
        components: {
            deleteIcon
        },
        data: () => ({
            picker:{},
            dateRange: {
                startDate: '',
                endDate: ''
            },
            localdate: {
                direction: 'ltr',
                format: 'mm/dd/yyyy',
                separator: ' - ',
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                weekLabel: 'W',
                customRangeLabel: 'Custom Range',
                daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                firstDay: 0
            },
            dateOfBirthMenu: false,
            language: uiHelper.getLanguage(),
            currentCurrency: uiHelper.getCurrency(),
            validator: formValidator,
            shared: SHARED,
            selectedTab: 'tier',
            dates: ['', ''],
            headers: [{
                    text: 'memberID',
                    align: 'start',
                    sortable: false,
                    value: 'member_id',
                },
                {
                    text: 'memberCode',
                    value: 'membercode',
                    sortable: false,
                },
                {
                    text: 'registerDate',
                    value: 'register_date_time',
                    sortable: true,
                    sortBy: 'asc'
                },
                {
                    text: 'firstDepositDate',
                    value: 'login_date_time',
                    sortable: false,
                },
                {
                    text: 'lastDepositDate',
                    value: 'last_deposit_date_time',
                    sortable: false,
                },
                {
                    text: 'affiliateLevel',
                    value: 'level',
                    sortable: true,
                    sortBy: 'asc'
                },
                {
                    text: 'dateSearch',
                    value: ''
                }
            ],
            downlines: [],
            filteredDownlines: [],
        }),
        created() {
            this.getReferralDownlines()
        },
        computed: {
            dateRangeText: {
                get(){
                    let startDate = this.dates[0] ? this.dates[0] : ''
                    let endDate = this.dates[1] ? this.dates[1] : ''
                    return startDate  + ' - ' + endDate
                },
                set(dateRange){
                    this.dates = []
                } 
            },
            isLoggedIn() {
                return this.$store.state.member.isLoggedIn
            },
            referralDownlines() {
                return this.$store.state.referral.downlines
            }
        },
        watch: {
            referralDownlines() {
                this.downlines = _.cloneDeep(this.$store.state.referral.downlines)
                this.filterListByDate()
                this.sortTableList()
            }
        },
        methods: {
            selectedDate() {
                if(this.dates[0] && this.dates[1]){
                    setTimeout(function () {
                        this.dateOfBirthMenu = false
                    }, 500);
                }
            },
            openMsgDialog(selectedMsg) {
                this.msgDialog = {
                    show: true,
                    msg: selectedMsg.message,
                    title: selectedMsg.subject
                }
            },
            closeMsgDialog() {
                this.msgDialog = {
                    show: false
                }
            },
            getReferralReports() {
                if (this.isLoggedIn)
                    this.$store.dispatch(`${REFERRAL_GET_REPORT}`)
            },
            getReferralDownlines() {
                if (this.isLoggedIn)
                    this.$store.dispatch(`${REFERRAL_GET_DOWNLINES}`)
            },
            sortTableList(obj) {
                let sortBy = _.filter(this.headers, function(d) {
                    return d.sortable;
                });
                if (obj) {
                    let selected = _.find(sortBy, function(d) {
                        if (d.value == obj.value) {
                            d.sortBy = d.sortBy == 'asc' ? 'desc' : 'asc'
                            return true;
                        }
                    });
                }
                this.filteredDownlines = _.orderBy(this.filteredDownlines, _.map(sortBy, 'value'), _.map(sortBy, 'sortBy'));
            },
            filterListByDate () {
                if(this.dates[0] && this.dates[1]){
                    var startDate = new Date(this.dates[0]);
                    var endDate = new Date(this.dates[1]);
                    this.filteredDownlines = this.downlines.filter(function(r) { 
                        let date = new Date(r.register_date_time)
                        return date >= startDate && date <= endDate 
                    });
                } else {
                    return this.filteredDownlines =  _.cloneDeep(this.downlines)
                }
                
            },
            clearFilter () {
                this.dates = []
                this.filterListByDate()
            }
        }
    }
</script>

<style lang="scss">
.daterangepicker .calendars-container{
    min-width: 290px;
    max-width: 290px;
    top: 600px;
    left: 918px;
    transform-origin: left top;
    z-index: 8;
}
    .date-search {
        .v-input__slot {
            min-height: 40px !important;
        }
        .v-input__append-inner {
            margin-top: 8px;
        }
    }
    .referral-table-header {
        color: #ACACAC;
    }
    .member-id-btn {
        background-color: transparent !important;
        span {
            color: #3A3A3A !important;
            text-decoration: underline;
        }
    }
    .report-expansion-panel.v-expansion-panels{
        font-size: 14px !important;
        color: #3A3A3A !important;
        .v-expansion-panel-header {
            font-size: 14px !important;
            color: #3A3A3A;
            padding: 5px;
        }
        .expansion-panel-content {
            margin: 0px 15px;
            border: 1px solid #3A3A3A;
            border-radius: 5px;
            background-color: #EBEBEB;
            .v-expansion-panel-content__wrap {
                padding: 10px;
            }
        }
    }
</style>